import React from "react";

import { MainLayout } from "../../layout";

export default function OurWork() {
  return (
    <MainLayout>
      <p>Our Work</p>
    </MainLayout>
  );
}
